import { GroupByTypes } from '_v2/containers/Filters/models'
import { FilterKeys } from '_v2/containers/Filters/models'

export interface ReportMetadata {
  section?: ReportSectionKeys
  key: ReportKeys
  apiKey: ReportApiKeys
  title: ReportTitles
  path: ReportPaths
  defaultFilters: FilterKeys[]
  additionalFilters: FilterKeys[]
  groupByOptions?: string[]
  viewByOptions?: string[]
  toursGatedProperties?: boolean
  callIntelGatedProperties?: boolean
  callTranscriptGatedProperties?: boolean
}

export enum ReportTitles {
  ACTIVITY = 'Activity',
  ACTIVITY_EVENT = 'Activity',
  AD_SPEND = 'Ad Spend',
  AI = 'AI',
  APPOINTMENTS = 'Appointments',
  APPOINTMENTS_UPCOMING = 'Appointments',
  BOXSCORE = 'Boxscore',
  CALLS = 'Calls',
  CALL_LOG = 'Call Log',
  CONVERSIONS = 'Leases',
  CALL_INTELLIGENCE = 'Call Intelligence',
  CALL_INTELLIGENCE_OVERVIEW = 'Call Intelligence',
  CALL_INTELLIGENCE_CONVERSIONS = 'Call Intelligence',
  CALL_INTELLIGENCE_INTENTS = 'Call Intelligence',
  CALL_INTELLIGENCE_AGENT = 'Call Intelligence',
  CALL_TRANSCRIPTS = 'Call Transcripts',
  CRAIGSLIST = 'Craigslist',
  DRIP_CAMPAIGN = 'Drip Campaign',
  ENGAGEMENT = 'Engagement',
  ID_VERIFICATION = 'ID Verification',
  LOSS_REASONS = 'Loss Reasons',
  RENEWALS = 'Renewals',
  RESPONSE_TIME = 'Response Time',
  TOUR_ENGAGEMENT = 'Tour Engagement',
  TOUR_TYPE = 'Tour Type',
  OCCUPANCY = 'Occupancy',
  MTA = 'Multi-Touch Attributions',
  DEMOGRAPHICS = 'Demographics',
  INSIGHTS = 'Insights',
  // initial state set in Filters/index.tsx
  DEFAULT = '',
}

export enum ReportKeys {
  ACTIVITY = 'activity',
  ACTIVITY_EVENT = 'activity',
  AD_SPEND = 'ad-spend',
  AI = 'ai',
  CONVERSIONS = 'conversions',
  APPOINTMENTS = 'appointments',
  INSIGHTS = 'Insights',
  APPOINTMENTS_UPCOMING = 'appointments',
  CALL_INTELLIGENCE = 'call-intelligence',
  CALL_INTELLIGENCE_OVERVIEW = 'call-intelligence',
  CALL_INTELLIGENCE_CONVERSIONS = 'call-intelligence',
  CALL_INTELLIGENCE_INTENTS = 'call-intelligence',
  CALL_INTELLIGENCE_AGENT = 'call-intelligence',
  CALL_TRANSCRIPTS = 'call-transcripts',
  BOXSCORE = 'boxscore',
  CALLS = 'calls',
  CALL_LOG = 'call-log',
  CRAIGSLIST = 'craigslist',
  DRIP_CAMPAIGN = 'drip-campaign',
  ENGAGEMENT = 'engagement',
  ID_VERIFICATION = 'id-verification',
  LOSS_REASONS = 'loss-reasons',
  RENEWALS = 'renewals',
  RESPONSE_TIME = 'response-time',
  TOUR_ENGAGEMENT = 'tour-engagement',
  TOUR_TYPE = 'tour-type',
  OCCUPANCY = 'occupancy',
  MTA = 'multi-touch-attribution',
  DEMOGRAPHICS = 'demographics',
  // initial state set in Filters/index.tsx
  DEFAULT = '',
}

export enum ReportSectionKeys {
  ACTIVITY = 'Leads',
  ACTIVITY_EVENT = 'Leads',
  CONVERSIONS = 'Leases',
  AD_SPEND = 'Marketing',
  AI = 'AI',
  APPOINTMENTS = 'Visitors',
  APPOINTMENTS_UPCOMING = 'Visitors',
  BOXSCORE = 'My Knock',
  CALL_INTELLIGENCE = 'Leads',
  CALL_INTELLIGENCE_OVERVIEW = 'Leads',
  CALL_INTELLIGENCE_CONVERSIONS = 'Leads',
  CALL_INTELLIGENCE_INTENTS = 'Leads',
  CALL_INTELLIGENCE_AGENT = 'Leads',
  CALL_TRANSCRIPTS = 'Leads',
  CALLS = 'Leads',
  CALL_LOG = 'Leads',
  CRAIGSLIST = 'craigslist',
  DRIP_CAMPAIGN = 'Marketing',
  ENGAGEMENT = 'Leads',
  ID_VERIFICATION = 'Visitors',
  LOSS_REASONS = 'loss-reasons',
  RENEWALS = 'renewals',
  RESPONSE_TIME = 'response-time',
  TOUR_ENGAGEMENT = 'Visitors',
  TOUR_TYPE = 'Visitors',
  OCCUPANCY = 'Leases',
  MTA = 'Marketing',
  DEMOGRAPHICS = 'Visitors',
  INSIGHTS = 'Insights',

  // initial state set in Filters/index.tsx
  DEFAULT = '',
}

export enum ReportPaths {
  ACTIVITY = '/activity',
  ACTIVITY_EVENT = '/activity',
  AD_SPEND = '/ad-spend',
  AI = '/ai',
  APPOINTMENTS = '/appointments',
  APPOINTMENTS_UPCOMING = '/appointments',
  BOXSCORE = '/boxscore',
  CALLS = '/calls',
  CONVERSIONS = '/conversions',
  CALL_LOG = '/call-log',
  CALL_INTELLIGENCE_OVERVIEW = '/call-intelligence',
  CALL_INTELLIGENCE_CONVERSIONS = '/call-intelligence',
  CALL_INTELLIGENCE_INTENTS = '/call-intelligence',
  CALL_INTELLIGENCE_AGENT = '/call-intelligence',
  CALL_TRANSCRIPTS = '/call-transcripts',
  CRAIGSLIST = '/craigslist',
  DRIP_CAMPAIGN = '/drip-campaign',
  ENGAGEMENT = '/engagement',
  ID_VERIFICATION = '/id-verification',
  INSIGHTS = '/insights',
  LOSS_REASONS = '/loss-reasons',
  RENEWALS = '/renewals',
  RESPONSE_TIME = '/response-time',
  TOUR_ENGAGEMENT = '/tour-engagement',
  TOUR_TYPE = '/tour-type',
  MTA = '/multi-touch-attribution',
  OCCUPANCY = '/occupancy',
  DEMOGRAPHICS = '/demographics',
  // initial state set in Filters/index.tsx
  DEFAULT = '',
}

// ReportApiKeys are values used when saving report filters; modifying these keys will result in the API breaking.
// If these need to be modified, please make the corresponding changes in the API as well
export enum ReportApiKeys {
  ACTIVITY = 'activity',
  ACTIVITY_EVENT = 'activity',
  AD_SPEND = 'ad_spend',
  AI = 'ai',
  APPOINTMENTS = 'appointments',
  APPOINTMENTS_UPCOMING = 'appointments_upcoming',
  BOXSCORE = 'boxscore',
  CALLS = 'calls',
  CONVERSIONS = 'conversions',
  CALL_LOG = 'call_log',
  CALL_INTELLIGENCE_OVERVIEW = 'call-intelligence',
  CALL_INTELLIGENCE_CONVERSIONS = 'call-intelligence',
  CALL_INTELLIGENCE_INTENTS = 'call-intelligence',
  CALL_INTELLIGENCE_AGENT = 'call-intelligence',
  CALL_TRANSCRIPTS = 'call-transcripts',
  CRAIGSLIST = 'craigslist',
  DRIP_CAMPAIGN = 'drip-campaign',
  ENGAGEMENT = 'engagement',
  ID_VERIFICATION = 'id-verification',
  LOSS_REASONS = 'loss_reasons',
  RENEWALS = 'renewals',
  RESPONSE_TIME = 'response_time',
  TOUR_ENGAGEMENT = 'tour-engagement',
  TOUR_TYPE = 'tour-type',
  MTA = 'mta',
  OCCUPANCY = 'occupancy',
  INSIGHTS = 'insights',
  DEMOGRAPHICS = 'demographics',
  // initial state set in Filters/index.tsx
  DEFAULT = '',
}

export const REPORT_METADATA: ReportMetadata[] = [
  {
    section: ReportSectionKeys.ACTIVITY,
    key: ReportKeys.ACTIVITY,
    apiKey: ReportApiKeys.ACTIVITY,
    title: ReportTitles.ACTIVITY,
    path: ReportPaths.ACTIVITY,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.ACTIVITY_EVENT,
    key: ReportKeys.ACTIVITY_EVENT,
    apiKey: ReportApiKeys.ACTIVITY_EVENT,
    title: ReportTitles.ACTIVITY_EVENT,
    path: ReportPaths.ACTIVITY_EVENT,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.AD_SPEND,
    key: ReportKeys.AD_SPEND,
    apiKey: ReportApiKeys.AD_SPEND,
    title: ReportTitles.AD_SPEND,
    path: ReportPaths.AD_SPEND,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.AI,
    key: ReportKeys.AI,
    apiKey: ReportApiKeys.AI,
    title: ReportTitles.AI,
    path: ReportPaths.AI,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.APPOINTMENTS,
    key: ReportKeys.APPOINTMENTS,
    apiKey: ReportApiKeys.APPOINTMENTS,
    title: ReportTitles.APPOINTMENTS,
    path: ReportPaths.APPOINTMENTS,
    defaultFilters: [
      FilterKeys.DATE_RANGE,
      FilterKeys.PROPERTIES,
      FilterKeys.GROUP_BY,
      FilterKeys.DATE_RANGE_FUTURE,
    ],
    additionalFilters: [FilterKeys.AGENTS, FilterKeys.SOURCES],
    groupByOptions: [GroupByTypes.PROPERTY, GroupByTypes.AGENT, GroupByTypes.SOURCE],
  },
  {
    section: ReportSectionKeys.APPOINTMENTS_UPCOMING,
    key: ReportKeys.APPOINTMENTS_UPCOMING,
    apiKey: ReportApiKeys.APPOINTMENTS_UPCOMING,
    title: ReportTitles.APPOINTMENTS_UPCOMING,
    path: ReportPaths.APPOINTMENTS_UPCOMING,
    defaultFilters: [
      FilterKeys.DATE_RANGE_FUTURE,
      FilterKeys.PROPERTIES,
      FilterKeys.GROUP_BY,
      FilterKeys.DATE_RANGE,
    ],
    additionalFilters: [FilterKeys.AGENTS, FilterKeys.SOURCES],
    groupByOptions: [GroupByTypes.PROPERTY, GroupByTypes.AGENT, GroupByTypes.SOURCE],
  },
  {
    section: ReportSectionKeys.BOXSCORE,
    key: ReportKeys.BOXSCORE,
    apiKey: ReportApiKeys.BOXSCORE,
    title: ReportTitles.BOXSCORE,
    path: ReportPaths.BOXSCORE,
    defaultFilters: [
      FilterKeys.DATE_RANGE,
      FilterKeys.PROPERTY,
      FilterKeys.AGENTS,
      FilterKeys.NOT_A_PROSPECT,
    ],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.CALLS,
    key: ReportKeys.CALLS,
    apiKey: ReportApiKeys.CALLS,
    title: ReportTitles.CALLS,
    path: ReportPaths.CALLS,
    defaultFilters: [
      FilterKeys.PROPERTIES,
      FilterKeys.DATE_RANGE,
      FilterKeys.CALLER_TYPES_SELECT,
      FilterKeys.VIEW_BY,
    ],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.CALL_LOG,
    key: ReportKeys.CALL_LOG,
    apiKey: ReportApiKeys.CALL_LOG,
    title: ReportTitles.CALL_LOG,
    path: ReportPaths.CALL_LOG,
    defaultFilters: [FilterKeys.PROPERTIES, FilterKeys.DATE_RANGE],
    additionalFilters: [FilterKeys.CALLER_TYPES],
    callTranscriptGatedProperties: true,
  },
  {
    section: ReportSectionKeys.CALL_INTELLIGENCE,
    key: ReportKeys.CALL_INTELLIGENCE,
    apiKey: ReportApiKeys.CALL_INTELLIGENCE_OVERVIEW,
    title: ReportTitles.CALL_INTELLIGENCE_OVERVIEW,
    path: ReportPaths.CALL_INTELLIGENCE_OVERVIEW,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
    callIntelGatedProperties: true,
  },
  {
    section: ReportSectionKeys.CALL_INTELLIGENCE_OVERVIEW,
    key: ReportKeys.CALL_INTELLIGENCE_OVERVIEW,
    apiKey: ReportApiKeys.CALL_INTELLIGENCE_OVERVIEW,
    title: ReportTitles.CALL_INTELLIGENCE_OVERVIEW,
    path: ReportPaths.CALL_INTELLIGENCE_OVERVIEW,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
    callIntelGatedProperties: true,
  },
  {
    section: ReportSectionKeys.CALL_INTELLIGENCE_CONVERSIONS,
    key: ReportKeys.CALL_INTELLIGENCE_CONVERSIONS,
    apiKey: ReportApiKeys.CALL_INTELLIGENCE_CONVERSIONS,
    title: ReportTitles.CALL_INTELLIGENCE_CONVERSIONS,
    path: ReportPaths.CALL_INTELLIGENCE_CONVERSIONS,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
    callIntelGatedProperties: true,
  },
  {
    section: ReportSectionKeys.CONVERSIONS,
    key: ReportKeys.CONVERSIONS,
    apiKey: ReportApiKeys.CONVERSIONS,
    title: ReportTitles.CONVERSIONS,
    path: ReportPaths.CONVERSIONS,
    defaultFilters: [],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.CALL_INTELLIGENCE_INTENTS,
    key: ReportKeys.CALL_INTELLIGENCE_INTENTS,
    apiKey: ReportApiKeys.CALL_INTELLIGENCE_INTENTS,
    title: ReportTitles.CALL_INTELLIGENCE_INTENTS,
    path: ReportPaths.CALL_INTELLIGENCE_INTENTS,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
    callIntelGatedProperties: true,
  },
  {
    section: ReportSectionKeys.CALL_INTELLIGENCE_AGENT,
    key: ReportKeys.CALL_INTELLIGENCE_AGENT,
    apiKey: ReportApiKeys.CALL_INTELLIGENCE_AGENT,
    title: ReportTitles.CALL_INTELLIGENCE_AGENT,
    path: ReportPaths.CALL_INTELLIGENCE_AGENT,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
    callIntelGatedProperties: true,
  },
  {
    section: ReportSectionKeys.CALL_TRANSCRIPTS,
    key: ReportKeys.CALL_TRANSCRIPTS,
    apiKey: ReportApiKeys.CALL_TRANSCRIPTS,
    title: ReportTitles.CALL_TRANSCRIPTS,
    path: ReportPaths.CALL_TRANSCRIPTS,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
    callTranscriptGatedProperties: true,
  },
  {
    section: ReportSectionKeys.CALL_INTELLIGENCE_OVERVIEW,
    key: ReportKeys.CALL_INTELLIGENCE_OVERVIEW,
    apiKey: ReportApiKeys.CALL_INTELLIGENCE_OVERVIEW,
    title: ReportTitles.CALL_INTELLIGENCE_OVERVIEW,
    path: ReportPaths.CALL_INTELLIGENCE_OVERVIEW,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTY],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.CALL_INTELLIGENCE_CONVERSIONS,
    key: ReportKeys.CALL_INTELLIGENCE_CONVERSIONS,
    apiKey: ReportApiKeys.CALL_INTELLIGENCE_CONVERSIONS,
    title: ReportTitles.CALL_INTELLIGENCE_CONVERSIONS,
    path: ReportPaths.CALL_INTELLIGENCE_CONVERSIONS,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTY],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.CALL_INTELLIGENCE_INTENTS,
    key: ReportKeys.CALL_INTELLIGENCE_INTENTS,
    apiKey: ReportApiKeys.CALL_INTELLIGENCE_INTENTS,
    title: ReportTitles.CALL_INTELLIGENCE_INTENTS,
    path: ReportPaths.CALL_INTELLIGENCE_INTENTS,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTY],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.CALL_INTELLIGENCE_AGENT,
    key: ReportKeys.CALL_INTELLIGENCE_AGENT,
    apiKey: ReportApiKeys.CALL_INTELLIGENCE_AGENT,
    title: ReportTitles.CALL_INTELLIGENCE_AGENT,
    path: ReportPaths.CALL_INTELLIGENCE_AGENT,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTY],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.CRAIGSLIST,
    key: ReportKeys.CRAIGSLIST,
    apiKey: ReportApiKeys.CRAIGSLIST,
    title: ReportTitles.CRAIGSLIST,
    path: ReportPaths.CRAIGSLIST,
    defaultFilters: [],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.DRIP_CAMPAIGN,
    key: ReportKeys.DRIP_CAMPAIGN,
    apiKey: ReportApiKeys.DRIP_CAMPAIGN,
    title: ReportTitles.DRIP_CAMPAIGN,
    path: ReportPaths.DRIP_CAMPAIGN,
    defaultFilters: [],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.ENGAGEMENT,
    key: ReportKeys.ENGAGEMENT,
    apiKey: ReportApiKeys.ENGAGEMENT,
    title: ReportTitles.ENGAGEMENT,
    path: ReportPaths.ENGAGEMENT,
    defaultFilters: [],
    additionalFilters: [],
  },
  {
    key: ReportKeys.ID_VERIFICATION,
    apiKey: ReportApiKeys.ID_VERIFICATION,
    title: ReportTitles.ID_VERIFICATION,
    path: ReportPaths.ID_VERIFICATION,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
    toursGatedProperties: true,
  },
  {
    key: ReportKeys.INSIGHTS,
    apiKey: ReportApiKeys.INSIGHTS,
    title: ReportTitles.INSIGHTS,
    path: ReportPaths.INSIGHTS,
    defaultFilters: [],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.LOSS_REASONS,
    key: ReportKeys.LOSS_REASONS,
    apiKey: ReportApiKeys.LOSS_REASONS,
    title: ReportTitles.LOSS_REASONS,
    path: ReportPaths.LOSS_REASONS,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [FilterKeys.AGENTS, FilterKeys.SOURCES, FilterKeys.CONTACTS],
  },
  {
    section: ReportSectionKeys.MTA,
    key: ReportKeys.MTA,
    apiKey: ReportApiKeys.MTA,
    title: ReportTitles.MTA,
    path: ReportPaths.MTA,
    additionalFilters: [],
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
  },
  {
    section: ReportSectionKeys.RENEWALS,
    key: ReportKeys.RENEWALS,
    apiKey: ReportApiKeys.RENEWALS,
    title: ReportTitles.RENEWALS,
    path: ReportPaths.RENEWALS,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.RESPONSE_TIME,
    key: ReportKeys.RESPONSE_TIME,
    apiKey: ReportApiKeys.RESPONSE_TIME,
    title: ReportTitles.RESPONSE_TIME,
    path: ReportPaths.RESPONSE_TIME,
    defaultFilters: [],
    additionalFilters: [],
  },
  {
    key: ReportKeys.TOUR_ENGAGEMENT,
    apiKey: ReportApiKeys.TOUR_ENGAGEMENT,
    title: ReportTitles.TOUR_ENGAGEMENT,
    path: ReportPaths.TOUR_ENGAGEMENT,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
    toursGatedProperties: true,
  },
  {
    key: ReportKeys.TOUR_TYPE,
    apiKey: ReportApiKeys.TOUR_TYPE,
    title: ReportTitles.TOUR_TYPE,
    path: ReportPaths.TOUR_TYPE,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
  },
  {
    key: ReportKeys.DEMOGRAPHICS,
    apiKey: ReportApiKeys.DEMOGRAPHICS,
    title: ReportTitles.DEMOGRAPHICS,
    path: ReportPaths.DEMOGRAPHICS,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
    toursGatedProperties: true,
  },
  {
    key: ReportKeys.OCCUPANCY,
    apiKey: ReportApiKeys.OCCUPANCY,
    title: ReportTitles.OCCUPANCY,
    path: ReportPaths.OCCUPANCY,
    defaultFilters: [FilterKeys.PROPERTIES],
    additionalFilters: [],
  },
  {
    section: ReportSectionKeys.DEFAULT,
    key: ReportKeys.DEFAULT,
    apiKey: ReportApiKeys.DEFAULT,
    title: ReportTitles.DEFAULT,
    path: ReportPaths.DEFAULT,
    defaultFilters: [],
    additionalFilters: [],
  },
]

export const defaultReportMeta: ReportMetadata = {
  section: ReportSectionKeys.DEFAULT,
  key: ReportKeys.DEFAULT,
  apiKey: ReportApiKeys.DEFAULT,
  title: ReportTitles.DEFAULT,
  path: ReportPaths.DEFAULT,
  defaultFilters: [],
  additionalFilters: [],
}

// ReportProvider
export enum ActionTypes {
  SET_REPORT = 'SET_REPORT',
  SET_ERROR = 'SET_ERROR',
}

export type Action = { type: ActionTypes.SET_REPORT; key: string } | { type: ActionTypes.SET_ERROR }
