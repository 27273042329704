import { GroupByTypes, ViewByTypes } from './../Filters/models'
import { FilterKeys } from '_v2/containers/Filters/models'

export interface ReportMetadata {
  key: ReportKeys
  apiKey: ReportApiKeys
  title: ReportTitles
  path: ReportPaths
  defaultFilters: FilterKeys[]
  additionalFilters: FilterKeys[]
  groupByOptions?: string[]
  viewByOptions?: string[]
  viewAsOptions?: string[]
}

export enum ReportTitles {
  ACTIVITY = 'Activity',
  ACTIVITY_EVENT = 'Activity',
  AD_SPEND = 'Ad Spend',
  APPOINTMENTS = 'Appointments',
  APPOINTMENTS_UPCOMING = 'Appointments',
  BOXSCORE = 'Boxscore',
  CALLS = 'Calls',
  CALL_LOG = 'Call Log',
  CRAIGSLIST = 'Craigslist',
  ENGAGEMENT = 'Engagement',
  ID_VERIFICATION = 'ID Verification',
  LOSS_REASONS = 'Loss Reasons',
  RENEWALS = 'Renewals',
  RESPONSE_TIME = 'Response Time',
  TOUR_TYPE = 'Tour Type',
  MTA = 'MTA',
  // initial state set in Filters/index.tsx
  DEFAULT = '',
}

export enum ReportKeys {
  ACTIVITY = 'activity',
  ACTIVITY_EVENT = 'activity',
  AD_SPEND = 'ad-spend',
  APPOINTMENTS = 'appointments',
  APPOINTMENTS_UPCOMING = 'appointments',
  BOXSCORE = 'boxscore',
  CALLS = 'calls',
  CALL_LOG = 'call-log',
  CRAIGSLIST = 'craigslist',
  ENGAGEMENT = 'engagement',
  ID_VERIFICATION = 'verification',
  LOSS_REASONS = 'loss-reasons',
  RENEWALS = 'renewals',
  RESPONSE_TIME = 'response-time',
  TOUR_TYPE = 'tour-type',
  MTA = 'mta',
  // initial state set in Filters/index.tsx
  DEFAULT = '',
}

export enum ReportPaths {
  ACTIVITY = '/activity',
  ACTIVITY_EVENT = '/activity',
  AD_SPEND = '/ad-spend',
  APPOINTMENTS = '/appointments',
  APPOINTMENTS_UPCOMING = '/appointments',
  BOXSCORE = '/boxscore',
  CALLS = '/calls',
  CALL_LOG = '/call-log',
  CRAIGSLIST = '/craigslist',
  ENGAGEMENT = '/engagement',
  ID_VERIFICATION = '/verification',
  LOSS_REASONS = '/loss-reasons',
  RENEWALS = '/renewals',
  RESPONSE_TIME = '/response-time',
  TOUR_TYPE = '/tour-type',
  MTA = '/multi-touch-attribution',
  // initial state set in Filters/index.tsx
  DEFAULT = '',
}

// ReportApiKeys are values used when saving report filters; modifying these keys will result in the API breaking.
// If these need to be modified, please make the corresponding changes in the API as well
export enum ReportApiKeys {
  ACTIVITY = 'activity',
  ACTIVITY_EVENT = 'activity_event',
  AD_SPEND = 'ad_spend',
  APPOINTMENTS = 'appointments',
  APPOINTMENTS_UPCOMING = 'appointments_upcoming',
  BOXSCORE = 'boxscore',
  CALLS = 'calls',
  CALL_LOG = 'call_log',
  CRAIGSLIST = 'craigslist',
  ENGAGEMENT = 'engagement',
  ID_VERIFICATION = 'verification',
  LOSS_REASONS = 'loss_reasons',
  RENEWALS = 'renewals',
  RESPONSE_TIME = 'response_time',
  TOUR_TYPE = 'tour-type',
  MTA = 'mta',
  // initial state set in Filters/index.tsx
  DEFAULT = '',
}

export const REPORT_METADATA: ReportMetadata[] = [
  {
    key: ReportKeys.ACTIVITY,
    apiKey: ReportApiKeys.ACTIVITY,
    title: ReportTitles.ACTIVITY,
    path: ReportPaths.ACTIVITY,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [FilterKeys.INCLUDE_NON_PROSPECTS, FilterKeys.PROSPECTS_BY_CREATE_DATE],
  },
  {
    key: ReportKeys.ACTIVITY_EVENT,
    apiKey: ReportApiKeys.ACTIVITY_EVENT,
    title: ReportTitles.ACTIVITY_EVENT,
    path: ReportPaths.ACTIVITY_EVENT,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [FilterKeys.INCLUDE_NON_PROSPECTS, FilterKeys.PROSPECTS_BY_CREATE_DATE],
  },
  {
    key: ReportKeys.AD_SPEND,
    apiKey: ReportApiKeys.AD_SPEND,
    title: ReportTitles.AD_SPEND,
    path: ReportPaths.AD_SPEND,
    defaultFilters: [],
    additionalFilters: [],
  },
  {
    key: ReportKeys.APPOINTMENTS,
    apiKey: ReportApiKeys.APPOINTMENTS,
    title: ReportTitles.APPOINTMENTS,
    path: ReportPaths.APPOINTMENTS,
    defaultFilters: [
      FilterKeys.DATE_RANGE,
      FilterKeys.PROPERTIES,
      FilterKeys.GROUP_BY,
      FilterKeys.DATE_RANGE_FUTURE,
    ],
    additionalFilters: [FilterKeys.AGENTS, FilterKeys.SOURCES],
    groupByOptions: [GroupByTypes.PROPERTY, GroupByTypes.AGENT, GroupByTypes.SOURCE],
  },
  {
    key: ReportKeys.APPOINTMENTS_UPCOMING,
    apiKey: ReportApiKeys.APPOINTMENTS_UPCOMING,
    title: ReportTitles.APPOINTMENTS_UPCOMING,
    path: ReportPaths.APPOINTMENTS_UPCOMING,
    defaultFilters: [
      FilterKeys.DATE_RANGE_FUTURE,
      FilterKeys.PROPERTIES,
      FilterKeys.GROUP_BY,
      FilterKeys.DATE_RANGE,
    ],
    additionalFilters: [FilterKeys.AGENTS, FilterKeys.SOURCES],
    groupByOptions: [GroupByTypes.PROPERTY, GroupByTypes.AGENT, GroupByTypes.SOURCE],
  },
  {
    key: ReportKeys.BOXSCORE,
    apiKey: ReportApiKeys.BOXSCORE,
    title: ReportTitles.BOXSCORE,
    path: ReportPaths.BOXSCORE,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTY],
    additionalFilters: [
      FilterKeys.AGENTS,
      FilterKeys.FIRST_CONTACT_ONLY,
      FilterKeys.INCLUDE_NON_PROSPECTS,
      FilterKeys.SHOW_APPLICATIONS,
    ],
  },
  {
    key: ReportKeys.CALLS,
    apiKey: ReportApiKeys.CALLS,
    title: ReportTitles.CALLS,
    path: ReportPaths.CALLS,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES, FilterKeys.GROUP_BY],
    additionalFilters: [
      FilterKeys.CALLER_TYPES,
      FilterKeys.SOURCES,
      FilterKeys.STATUSES,
      FilterKeys.DAYS_HOURS,
    ],
    groupByOptions: [
      GroupByTypes.PROPERTY,
      GroupByTypes.SOURCE,
      GroupByTypes.SOURCE_ATTRIBUTE,
      GroupByTypes.STATUS,
      GroupByTypes.CALLER_TYPE,
      GroupByTypes.DAY,
      GroupByTypes.HOUR,
    ],
    viewByOptions: [ViewByTypes.COUNT, ViewByTypes.PERCENTAGE],
  },
  {
    key: ReportKeys.CALL_LOG,
    apiKey: ReportApiKeys.CALL_LOG,
    title: ReportTitles.CALL_LOG,
    path: ReportPaths.CALL_LOG,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTY],
    additionalFilters: [FilterKeys.CALLER_TYPES],
  },
  {
    key: ReportKeys.CRAIGSLIST,
    apiKey: ReportApiKeys.CRAIGSLIST,
    title: ReportTitles.CRAIGSLIST,
    path: ReportPaths.CRAIGSLIST,
    defaultFilters: [],
    additionalFilters: [],
  },
  {
    key: ReportKeys.ENGAGEMENT,
    apiKey: ReportApiKeys.ENGAGEMENT,
    title: ReportTitles.ENGAGEMENT,
    path: ReportPaths.ENGAGEMENT,
    defaultFilters: [],
    additionalFilters: [],
  },
  {
    key: ReportKeys.ID_VERIFICATION,
    apiKey: ReportApiKeys.ID_VERIFICATION,
    title: ReportTitles.ID_VERIFICATION,
    path: ReportPaths.ID_VERIFICATION,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
  },
  {
    key: ReportKeys.LOSS_REASONS,
    apiKey: ReportApiKeys.LOSS_REASONS,
    title: ReportTitles.LOSS_REASONS,
    path: ReportPaths.LOSS_REASONS,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [FilterKeys.AGENTS, FilterKeys.SOURCES, FilterKeys.CONTACTS],
  },
  {
    key: ReportKeys.MTA,
    apiKey: ReportApiKeys.MTA,
    title: ReportTitles.MTA,
    path: ReportPaths.MTA,
    // defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
    // TODO: For Future version of MTA
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES, FilterKeys.VIEW_BY],
    // additionalFilters: [FilterKeys.SOURCES, FilterKeys.VIEW_BY],
    // groupByOptions: [GroupByTypes.SOURCE, GroupByTypes.INSERT_METHOD],
    viewByOptions: [ViewByTypes.ALL, ViewByTypes.LEASE_CONVERSION, ViewByTypes.VISIT_CONVERSION],
  },
  {
    key: ReportKeys.RENEWALS,
    apiKey: ReportApiKeys.RENEWALS,
    title: ReportTitles.RENEWALS,
    path: ReportPaths.RENEWALS,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
  },
  {
    key: ReportKeys.RESPONSE_TIME,
    apiKey: ReportApiKeys.RESPONSE_TIME,
    title: ReportTitles.RESPONSE_TIME,
    path: ReportPaths.RESPONSE_TIME,
    defaultFilters: [],
    additionalFilters: [],
  },
  {
    key: ReportKeys.TOUR_TYPE,
    apiKey: ReportApiKeys.TOUR_TYPE,
    title: ReportTitles.TOUR_TYPE,
    path: ReportPaths.TOUR_TYPE,
    defaultFilters: [FilterKeys.DATE_RANGE, FilterKeys.PROPERTIES],
    additionalFilters: [],
  },
  {
    key: ReportKeys.DEFAULT,
    apiKey: ReportApiKeys.DEFAULT,
    title: ReportTitles.DEFAULT,
    path: ReportPaths.DEFAULT,
    defaultFilters: [],
    additionalFilters: [],
  },
]

// ReportProvider
export enum ActionTypes {
  SET_REPORT = 'SET_REPORT',
  SET_ERROR = 'SET_ERROR',
}
