import { orderBy } from 'lodash'
import { DAYS, HOURS, DaysHoursValue } from './models'

export const getTimeRangeFromStartEnd = (startTime: number, endTime: number): DaysHoursValue[] => {
  let timeRange: DaysHoursValue[] = []

  // get all hours between startTime and endTime
  let allHours: number[] = []
  let currentHour = startTime
  while (currentHour <= endTime) {
    allHours.push(currentHour)
    currentHour++
  }

  // map each hour to daysHours object
  const mappedHours = allHours.map((ah) => mapValueToHour(ah))
  if (mappedHours) {
    timeRange = orderBy(mappedHours, ['value'], 'asc')
  }
  return timeRange
}

export const mapValueToHour = (value: number): DaysHoursValue => {
  let selectedHour: DaysHoursValue = { title: '', value: -1 }

  const mappedHour = HOURS.find((hrs) => hrs.value === value)
  if (mappedHour) {
    selectedHour = { title: mappedHour.title, value: mappedHour.value }
  }
  return selectedHour
}

export const transformRedirectDaysHours = (values: number[], field: 'days' | 'hours') => {
  let transformedValues: DaysHoursValue[] = []
  if (!values.length) return transformedValues

  if (field === 'days') {
    transformedValues = DAYS.filter((s) => {
      return values.includes(s.value)
    })
  } else {
    transformedValues = HOURS.filter((s) => {
      return values.includes(s.value)
    })
  }

  return transformedValues
}
