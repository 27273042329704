export const capitalize = (string?: string) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.substring(1)
}

// Words that we generally do not want to capitalize
// e.g. we want "Lack of Leases", not "Lack Of Leases"
const plainWords = new Set(['the', 'of', 'to'])

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const makeFirstLettersCapital = (
  rawStr: string,
  usePlainwords?: boolean,
  plainWordList?: string[]
) => {
  if (usePlainwords) {
    const customPlainWords =
      plainWordList && plainWordList.length ? new Set(plainWordList) : plainWords
    return rawStr
      .split(/[\s_]+/)
      .map((word) => (customPlainWords.has(word) ? word : capitalize(word)))
      .join(' ')
  }
  return rawStr
    .split(/[\s_]+/)
    .map(capitalize)
    .join(' ')
}

// example thisWord = this Word
export const addSpaceOnEveryCapitalLetter = (value: string) => {
  return value.replace(/([A-Z])/g, ' $1').trim()
}

// loweCase camel Case
// Example 'First Name' => firstName
export const toLowerCamelCase = (value: string) => {
  const arrayOfWords = value.split(' ')
  const newWords = arrayOfWords.map((newWord, index) =>
    index === 0 ? newWord.toLowerCase() : capitalize(newWord)
  )
  return newWords.join('')
}

export const sortString = (
  rowA: { values: { [x: string]: string } },
  rowB: { values: { [x: string]: string } },
  columnId: string
) => {
  const valueA = (rowA.values[columnId] || '').toLowerCase()
  const valueB = (rowB.values[columnId] || '').toLowerCase()

  return valueA.localeCompare(valueB)
}

export const tryJSONParse = (data: string) => {
  try {
    return JSON.parse(data)
  } catch (error) {
    return null
  }
}
export const getLocalStorageBoolean = (key: string): boolean => {
  const storedData = localStorage.getItem(key)
  if (!storedData) return false
  const parsedData = tryJSONParse(storedData)
  return Boolean(parsedData)
}
