// models
import { REPORT_METADATA } from '../Reports/models'

//
// NOTE: This can be eliminated once the final dependencies are migrated:
// FavoriteReportDialog - can be removed once all reports have the new ReportActionBar
// MyReports/SavedReports/services.ts - filterServiceFromApiToPrettyPrint()
// |_ this service relies on transforming the `start` and `end` dates from ` * ` to today()
// |_ to safely remove, consider having the backend return today's date when end = '*'

export const hasRequiredFilter = (path, filter) => {
  const selectedReport = REPORT_METADATA.find((report) => path === report.path)
  if (!selectedReport) return false
  return (
    selectedReport.defaultFilters.includes(filter) ||
    selectedReport.additionalFilters.includes(filter)
  )
}
