// knock brand colors
export const red = '#ED1E79'
export const green = '#25B786'
export const yellow = '#FBB03B'
export const blue_link = '#0000EE'
export const white = '#FFFFFF'
export const gray_ultra_light = '#F8F9FA'
export const benchmark_gray = '#B0B0B0'

// knock dark blue
export const blue_dark = {
  brand: '#103659',
  lightened_10: '#2A5073',
  lightened_30: '#5D83A6',
  lightened_50: '#8FB5D8',
  darkened_10: '#001D40',
}

export const blue_light = {
  brand: '#3fa9f5',
  lightened_10: '#6fbff8',
  lightened_30: '#d0eafd',
  darkened_10: '#0f93f2',
  darkened_30: '#085993',
}

// knock grays
export const gray = {
  brand: '#F8F9FA',
  darkened_10: '#DFE0E1',
  darkened_30: '#ACADAE',
  darkened_50: '#797A7B',
  borders: '#B9C3CC',
  muiTableBorders: '#E0E0E0',
}

// primary component colors
export const color__primary = blue_light.brand
export const color__secondary = blue_dark.brand
export const container__background = gray_ultra_light

export const text__primary_gray_dark = '#535353'
export const text__tertiary_gray_light = '#ADB5BD' // footer text

// status colors
// material defaults in comments
export const status__danger_red = '#ff4444'

// export const info_blue = '#1976D2'
