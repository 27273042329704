import { getUser, getFeatureFlags } from './auth'
import GlobalDataService from 'GlobalDataService'
import { IS_CALL_INTEL_STANDALONE_ANA_ENABLED } from './featureFlagService'

// TODO: document-- basically, this shit makes sure we have some sort of
// user data before we actually load the app
async function fetchAppData() {
  const user = await getUser()

  if (!user) {
    return { user: null }
  }

  // Do not call feature flags unless there is a user
  const featureFlags = await getFeatureFlags(user.company_id)
  user.featureFlags = featureFlags
  user.banners = []

  // Prevent tests from calling GlobalDataService
  let global_data_service = {}

  if (process.env.NODE_ENV !== 'test') {
    await GlobalDataService.initialize(featureFlags[IS_CALL_INTEL_STANDALONE_ANA_ENABLED])
    global_data_service = {
      agents: GlobalDataService.agents,
      agentsById: GlobalDataService.agents,
      company: GlobalDataService.company,
      properties: GlobalDataService.properties,
      propertiesById: GlobalDataService.propertiesById,
      marketingSources: GlobalDataService.marketingSources,
      propertyAttributes: GlobalDataService.propertyAttributes,
      propertyAttributesById: GlobalDataService.propertyAttributesById,
      propertyAttributesByType: GlobalDataService.propertyAttributesByType,
      toursGatedPropertyIds: GlobalDataService.toursGatedPropertyIds,
      callIntelGatedPropertyIds: GlobalDataService.callIntelGatedPropertyIds,
      callTranscriptGatedPropertyIds: GlobalDataService.callTranscriptGatedPropertyIds,
      callIntelStandalonePropertyIds: GlobalDataService.callIntelStandalonePropertyIds,
      aiGatedPropertyIds: GlobalDataService.aiGatedPropertyIds, // is it ai voice gated properties
      aiEmailGatedPropertyIds: GlobalDataService.aiEmailGatedPropertyIds,
      aiChatGatedPropertyIds: GlobalDataService.aiChatGatedPropertyIds,
      aiSmsGatedPropertyIds: GlobalDataService.aiSmsGatedPropertyIds,
      userData: GlobalDataService.userData,
    }
  }

  user.gatingFlags = {}
  if (global_data_service.toursGatedPropertyIds?.length) {
    user.gatingFlags.toursReportGating = true
  }
  if (global_data_service.callIntelGatedPropertyIds?.length) {
    user.gatingFlags.callIntelReportGating = true
  }
  if (global_data_service.callTranscriptGatedPropertyIds?.length) {
    user.gatingFlags.callTranscriptReportGating = true
  }
  if (
    global_data_service.aiGatedPropertyIds?.length ||
    global_data_service.aiEmailGatedPropertyIds?.length ||
    global_data_service.aiChatGatedPropertyIds?.length > 0 ||
    global_data_service.aiSmsGatedPropertyIds?.length > 0
  ) {
    user.gatingFlags.aiReportGating = true
  }

  if (global_data_service.aiGatedPropertyIds?.length) {
    user.gatingFlags.aiVoiceReportGating = true
  }

  if (global_data_service.aiEmailGatedPropertyIds?.length) {
    user.gatingFlags.aiEmailReportGating = true
  }

  if (global_data_service.aiChatGatedPropertyIds?.length) {
    user.gatingFlags.aiChatReportGating = true
  }

  if (global_data_service.aiSmsGatedPropertyIds?.length) {
    user.gatingFlags.aiSmsReportGating = true
  }

  if (global_data_service.userData) {
    user.username = global_data_service.userData.username
    user.email = global_data_service.userData.email
    user.fullname = global_data_service.userData.fullname
  }

  return {
    user,
    global_data_service,
  }
}

export { fetchAppData }
