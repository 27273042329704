export const GATED_PROPERTIES_QUERY = `
query GetGatedProperties {
    getGatedProperties {
      properties
      toursGatedProperties
      callIntelGatedProperties
      callTranscriptGatedProperties
      aiGatedProperties
      aiEmailGatedProperties
      callIntelStandaloneProperties
      aiChatGatedProperties
      aiSmsGatedProperties
    }
  }
`
